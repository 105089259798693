<template>
  <SignalTableOverlayStyled>
    <HeaderStyled> {{ $tc('signal', 1) | capitalize }} Export </HeaderStyled>
    <MainStyled>
      <div class="group" :key="indexGroup" v-for="(group, indexGroup) in groups">
        <div class="tabs">
          <div class="tab">
            <input class="input-table" type="checkbox" :id="`input${indexGroup}`" />
            <label class="tab-label" :for="`input${indexGroup}`">{{ group.asset.name }}</label>
            <div class="tab-content">
              <table>
                <tbody>
                  <tr>
                    <th>
                      <p>
                        <strong>Time</strong>
                      </p>
                    </th>
                    <th>
                      <p>
                        <strong>Asset Dimension</strong>
                      </p>
                    </th>
                    <th>
                      <p>
                        <strong>Unit</strong>
                      </p>
                    </th>
                    <th>
                      <p>
                        <strong>Value</strong>
                      </p>
                    </th>
                    <th>
                      <p>
                        <strong>#No. of Point</strong>
                      </p>
                    </th>
                    <th @click="deleteSelectedRange(indexGroup, group.chartId)">
                      <TrashIcon />
                    </th>
                  </tr>
                  <tr :key="indexPoint" v-for="(point, indexPoint) in group.points">
                    <td>
                      <p>{{ point.time }}</p>
                    </td>
                    <td>
                      <p>{{ point.sensorName }}</p>
                    </td>
                    <td>
                      <p>{{ point.unit }}</p>
                    </td>
                    <td>
                      <p>{{ point.value }}</p>
                    </td>
                    <td>
                      <p>#{{ indexPoint + 1 }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <button @click="downloadCSV(group)">
            <TalpaIcon :scope="'Misc'" :name="'CSV'" />
          </button>
        </div>
      </div>
    </MainStyled>
  </SignalTableOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { TalpaIcon } from '@common/components'

import { TrashIcon } from 'vue-feather-icons'

import { downloadCSVFile } from '@/utils/export'

import {
  flexCenter,
  // flexColumns,
  // input,
} from '@styles/mixins'
import { TimeframeMixin } from '@common/mixins'

const SignalTableOverlayStyled = styled('div')`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main';
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  grid-area: header;
  padding: 1rem;
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`

const MainStyled = styled('div')`
  grid-area: main;
  display: grid;
  width: calc(100% - 2rem);
  padding: 1rem;
  overflow: auto;
  scroll-behavior: smooth;
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  mixins: [TimeframeMixin],
  components: {
    SignalTableOverlayStyled,
    HeaderStyled,
    MainStyled,
    TalpaIcon,
    TrashIcon,
  },
  data() {
    return {
      groups: [],
    }
  },
  mounted() {
    this.groups = get(this.args, 'groups', [])
  },
  methods: {
    downloadCSV(group) {
      const formattedDate = this.selectedInterval.start.setLocale('de-de').toLocaleString()
      const filename = `${group.asset.name} - ${formattedDate}.csv`
      const data = group.points.map((item, index) => {
        return {
          ...item,
          number: index + 1,
        }
      })
      let csvFile = 'Date,Time Zone,Time,Asset Dimension,Unit,Value,#No. of point\n'
      data.forEach(row => {
        csvFile += this.objectKeysToString(row, ['date', 'timeZone', 'time', 'sensorName', 'unit', 'value', 'number'], ',')
        csvFile += '\n'
      })

      downloadCSVFile(csvFile, filename)
    },
    objectKeysToString(object, keys, separator) {
      return keys
        .map(key => object[key])
        .filter(v => v)
        .join(separator)
    },
    deleteSelectedRange(indexGroup, chartId) {
      this.groups.splice(indexGroup, 1)
      this.$root.$emit('removeSelectedRange', { chartId })
    },
  },
}
</script>
<style lang="scss" scoped>
th,
td {
  border: 1px solid white;
}
table {
  border-collapse: collapse;
  border: 1px solid white;
  table-layout: auto;
  width: 100%;
  text-align: center;
}

$midnight: #343b3d;
$clouds: #0d3034;

.input-table {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  margin-top: 0.5em;
  border-radius: 4px;
  overflow: hidden;
}
.tab {
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    color: white;
    background: $midnight;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($midnight, 10%);
    }
    &::after {
      content: '\276F';
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.05s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $midnight;
    background: white;
    transition: all 0.05s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75em;
    padding: 1em;
    background: $midnight;
    cursor: pointer;
    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken($midnight, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100%;
    padding: 1em;
  }
}
</style>
