var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SignalTableOverlayStyled', [_c('HeaderStyled', [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.$tc('signal', 1))) + " Export ")]), _c('MainStyled', _vm._l(_vm.groups, function (group, indexGroup) {
    return _c('div', {
      key: indexGroup,
      staticClass: "group"
    }, [_c('div', {
      staticClass: "tabs"
    }, [_c('div', {
      staticClass: "tab"
    }, [_c('input', {
      staticClass: "input-table",
      attrs: {
        "type": "checkbox",
        "id": "input".concat(indexGroup)
      }
    }), _c('label', {
      staticClass: "tab-label",
      attrs: {
        "for": "input".concat(indexGroup)
      }
    }, [_vm._v(_vm._s(group.asset.name))]), _c('div', {
      staticClass: "tab-content"
    }, [_c('table', [_c('tbody', [_c('tr', [_c('th', [_c('p', [_c('strong', [_vm._v("Time")])])]), _c('th', [_c('p', [_c('strong', [_vm._v("Asset Dimension")])])]), _c('th', [_c('p', [_c('strong', [_vm._v("Unit")])])]), _c('th', [_c('p', [_c('strong', [_vm._v("Value")])])]), _c('th', [_c('p', [_c('strong', [_vm._v("#No. of Point")])])]), _c('th', {
      on: {
        "click": function click($event) {
          return _vm.deleteSelectedRange(indexGroup, group.chartId);
        }
      }
    }, [_c('TrashIcon')], 1)]), _vm._l(group.points, function (point, indexPoint) {
      return _c('tr', {
        key: indexPoint
      }, [_c('td', [_c('p', [_vm._v(_vm._s(point.time))])]), _c('td', [_c('p', [_vm._v(_vm._s(point.sensorName))])]), _c('td', [_c('p', [_vm._v(_vm._s(point.unit))])]), _c('td', [_c('p', [_vm._v(_vm._s(point.value))])]), _c('td', [_c('p', [_vm._v("#" + _vm._s(indexPoint + 1))])])]);
    })], 2)])])])]), _c('div', [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.downloadCSV(group);
        }
      }
    }, [_c('TalpaIcon', {
      attrs: {
        "scope": 'Misc',
        "name": 'CSV'
      }
    })], 1)])]);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }